body {
	background-color: 'red';
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: rgb(112, 76, 182);
}

.featurecard--gallery .image-gallery .image-gallery-svg {
	height: 22px !important;
	width: 17px !important;
}

.image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:active {
	border: 2px solid #007465;
	cursor: pointer;
}
.image-gallery .image-gallery-thumbnails .image-gallery-thumbnail {
	border: 0px solid #007465;
	margin-top: 5px !important;
	cursor: pointer;
}
.image-gallery-fullscreen-button {
	border: 0px !important;
}
.home--productcategory__image {
	/* border-radius: 50% !important; */
	border: 1px solid #186f65 !important;
	/* min-width: 200px !important; */
	max-width: 160px !important;
	max-height: 160px !important;
}

.home--category__title,
.home--newly__title,
.home--bestseller__title,
.home--blogs__title,
.home--happycustomer__title,
.page--title__formobile {
	font-size: 17px !important;
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

.carousel .slider-wrapper {
	max-height: 500px !important;
}

.featurecard .carousel .slider-wrapper {
	max-height: 150px !important;
}
