@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
	font-family: 'Monomaniac One', sans-serif;
	font-family: 'Roboto', sans-serif;
}

code {
	font-family: 'Monomaniac One', sans-serif;
	font-family: 'Roboto', sans-serif;
}

.carousel-root {
	display: flex !important;
	flex-direction: column !important;
}

.carousel.carousel-slider .control-arrow {
	background-color: #186f65 !important;
}

.custom-carousel .carousel .slide img {
	height: 500px !important;
}

.carousel .control-dots {
	display: none !important;
}
