@media only screen and (max-width: 767px) {
	.home--header {
		height: 360px !important;
	}
	.home--header__title {
		font-size: 18px !important;
		font-weight: 600 !important;
	}
	.home--header__subtitle {
		font-size: 12px !important;
		padding: 8px 16px !important;
	}
	.home--header__cta {
		font-size: 12px !important;
	}
	.home--stripwrapper {
		padding: 15px 10px !important;
	}
	.home--stripwrapper__icon {
		font-size: 16px !important;
	}

	.home--stripwrapper__text {
		font-size: 10px !important;
		padding-bottom: 10px !important;
	}
	.home--category {
		padding-bottom: 0px !important;
		padding-top: 32px !important;
	}
	.home--product__wrapper {
		padding: 10px !important;
	}
	.home--category__title {
		padding-bottom: 16px !important;
		font-size: 15px !important;
	}
	.home--newly,
	.home--bestsellerwrapper,
	.home--blogs {
		padding-top: 32px !important;
		padding-bottom: 32px !important;
	}

	.home--bestseller__title,
	.home--blogs__title,
	.home--happycustomer__title {
		font-size: 15px !important;
	}
	.home--newlyadded {
		padding-top: 16px !important;
		padding-bottom: 16px !important;
	}

	.home--happycustomer .testimonial-card {
		padding: 0px !important;
		width: 100% !important;
	}

	.home--newly__title {
		font-size: 15px !important;
	}
	.home--productcategory__single {
	}
	.home--productcategory {
		padding: 0px !important;
		display: flex;
		flex-wrap: wrap;
	}
	.home--productcategory__image {
		/* width: 100% !important; */
		max-height: 120px !important;
		min-height: 120px !important;
		/* max-width: 120px !important;
		min-width: 120px !important; */
	}
	.home--customers {
		flex-direction: column !important;
		gap: 15px !important;
	}
	/* .home--customers .testimonial-card {
		width: 83vw !important;
	} */

	.home--newlyadded {
		flex-direction: column !important;
		gap: 10px !important;
		padding-left: 0px !important;
	}
	.home--blogs__wrapper {
		flex-direction: column !important;
	}
	.header--logowrappermain {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.home--bestseller {
		flex-direction: column !important;
		gap: 10px !important;
		padding-left: 0px !important;
	}
	.header--primary__title {
		font-size: 10px !important;
	}
	.header--primary__icon {
		font-size: 18px !important;
	}
	.header--secondary {
		padding: 0px !important;
	}
	.header--secondary__wrapper {
		justify-content: flex-start !important;
		padding-left: 15px !important;
		flex-direction: row !important;
	}
	.header--secondary__wrapperfirst {
		display: flex !important;
		flex-direction: row !important;
		flex: 1 !important;
	}
	.header--secondary__wrapperfirstlogo {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		font-size: 10px !important;
	}
	.header--secondary__wrapperfirstlogo2 {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		font-size: 10px !important;
	}
	.header--secondary__wrapperthird {
		display: flex !important;
		flex: 2 !important;
		justify-content: flex-end !important;
	}

	.header--secondary__wrapperthirdtitle {
		display: none !important;
	}
	.header--secondary__innerwrapperthirdbtn {
		min-width: 40px !important;
	}

	.menu--icon {
		display: flex !important;
		color: #186f65 !important;
		margin-right: 5px !important;
		margin-left: 0px !important;
	}
	.footer--newsletter {
		flex-direction: column !important;
		padding: 40px 0px !important;
	}
	.footer--newsletter__title {
		font-size: 14px !important;
	}
	.footer--newsletter__form {
		flex-direction: column !important;
		gap: 45px !important;
		justify-content: center !important;
		align-items: center !important;
	}
	.footer--newsletter__formemail {
		width: 90vw !important;
	}
	.footer--newsletter__formbutton {
		font-size: 12px !important;
		height: 40px !important;
		margin-top: 10px !important;
	}
	.footer--sitemap {
		padding: 10px !important;
		flex-direction: column !important;
		gap: 20px !important;
	}
	.footer--sitemap__info {
		display: flex !important;
		flex-direction: column !important;
		gap: 0px !important;
	}
	.footer--sitemap__info h6 {
		font-size: 12px !important;
		padding-bottom: 20px !important;
	}
	.footer--sitemap__pagewrapper {
		padding: 0px 0px 15px !important;
	}
	.footer--sitemap__page {
	}
	.footer--sitemap__imppage {
		flex: 2 !important;
	}
	.footer--sitemap__social {
	}

	.blogs {
		flex-direction: column !important;
		gap: 20px !important;
	}
	.blogs--category {
	}
	.blogs--item {
		flex: 1 !important;
		gap: 18px !important;
		padding: 0px !important;
	}

	/* Blog card css*/

	.blog--card {
		display: flex !important;
		width: 100% !important;
	}
	.blog--card__image {
		width: 160px !important;
		height: 160px !important;
	}
	.blog--card__content {
	}
	.blog--card__title {
	}
	.blog--card__chip {
	}
	.blog--card__description {
		display: none !important;
	}
	.blog--card__buttonwrapper {
		justify-content: flex-start !important;
	}
	.blog--card__button {
	}
	.single---blog__image {
		height: 300px !important;
	}
	.product {
		display: flex !important;
		flex-direction: column !important;
		gap: 20px;
	}
	.product--category {
		z-index: 1000 !important;
	}
	.product--items {
		padding: 0px !important;
	}
	.product--items__inner {
		gap: 10px !important;
	}
	.featurecard {
		display: flex !important;
		max-width: none !important;
		/* background: #d5e7a7c4 !important; */
	}
	.featurecard--gallery {
		width: 180px !important;
		height: 145px !important;
	}
	.featurecard--image__wrapper .image-gallery {
	}
	.featurecard--content {
		width: calc(100vw - 260px) !important;
		background: white !important;
		padding: 10px !important;
		padding-left: 0px !important;
		/* padding-bottom: 0px !important; */
	}
	.featurecard--price {
		display: flex !important;
		align-items: 'center' !important;
		gap: 5px !important;
	}
	.featurecard--price h6 {
		font-size: 12px !important;
		padding-top: 8px !important;
	}
	.featurecard--title {
		font-size: 12px !important;
		font-weight: 500 !important;
	}
	.singleproduct {
		flex-direction: column !important;
	}

	.cart--table::-webkit-scrollbar {
		height: 5px !important;
	}

	.cart--table::-webkit-scrollbar-thumb {
		background-color: #186f65 !important;
		border-radius: 6px !important;
	}

	.cart--table::-webkit-scrollbar-track {
		background-color: transparent !important;
	}
	.singleorders__wrapper {
		display: 'flex' !important;
		flex-direction: column !important;
	}
	.wishlist__section {
		padding: 0px !important;
	}

	.wishlist--bestsetter__sectionwrapper {
		padding: 0px !important;
	}
	.wishlist--bestsetter__section {
		flex-direction: column !important;
	}
	.checkout__wrapper {
		flex-direction: column !important;
	}
	.shipping__wrapper {
		flex-direction: column !important;
	}
	.orderstatus__singlewrapper {
		display: flex !important;
		flex-direction: column-reverse !important;
		align-items: flex-start !important;
		gap: 5px !important;
	}
	.home--product__button h6 {
		font-size: 10px !important;
	}
	.header--logowrappermain img {
		width: 100px !important;
	}
	.page--title__formobile {
		font-size: 16px !important;
	}
	.featurecard--image__wrapper {
		width: 40% !important;
	}

	.featurecard .carousel .slider-wrapper {
		max-height: 135px !important;
	}

	.featurecard .custom-carousel .carousel .slide img {
		max-height: 135px !important;
	}
	.featurecard--image__wrapper video {
		height: 135px !important;
	}

	.singleproduct .artifact-wrapper {
		width: 100% !important;
	}

	.singleproduct .carousel .slider-wrapper {
		height: 250px !important;
	}

	.singleproduct .custom-carousel .carousel .slide img {
		max-height: 250px !important;
	}
	.singleproduct video {
		height: 250px !important;
	}
}
