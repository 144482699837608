.subscribe--input input {
	background-color: white;
}

.whyyoga--subtitle {
	color: white;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600 !important;
	font-size: 18px !important;
}

.whyyoga--subcontent {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300 !important;
	font-size: 15px !important;
	color: white;
}

.on {
	color: #fdcc0d;
}
.off {
	color: #ccc;
}
.star {
	font-size: 20px;
}
.image--wrapper {
}

.blog--cardcontent {
	padding-bottom: 8px !important;
}

.slick-prev {
	display: none !important;
}

.slick-next {
	display: none !important;
}
.slick-prev:hover,
.slick-next:hover {
	/* color: #11506e !important;
	border: 1px solid #92fea3 !important;
	background: white !important; */
	display: none !important;
}
/*
.slick-list {
	margin: 0px 30px !important;
}
.slick-current .blog--cardcontent {
	background-color: #e9e9e97d !important;
} */
